<template>
  <div>
    <RightSideBar
      v-if="isOpen"
      submit="Next"
      @close="isOpen = false"
      button-class="bg-dynamicBackBtn text-white"
      @submit="onSelectedEmployees(selectedItem)"
      :disabled-button="!(Object.keys(selectedItem).length > 0) || isFetchingEmployeeInfo"
    >
      <template v-slot:title>
        <p class="text-darkPurple">New Promotion</p>
      </template>
      <template v-slot:subtitle>
        <template v-if="!isFetchingEmployeeInfo">
          <p class="flex w-full mb-5">
            Search existing employee to promote
          </p>
        </template>
      </template>
      <template v-if="isFetchingEmployeeInfo">
        <div class="flex flex-col items-center">
          <loader size="xxs" :loader-image="false" />
        </div>
      </template>
      <template v-else>
        <search-input
          class="mb-8"
          @input="onSearchEmployees($event, 100)"
          v-model="searchInput"
          placeholder="Search"
        />
        <div style="height:100%" v-if="isLoading">
          <loader size="xxs" :loader-image="false" />
        </div>
        <div v-else>
          <div v-for="(item, index) in employees" :key="index" class="flex flex-col border-b border-dashed border-romanSilver p-2">
            <div class="flex items-center gap-2">
              <div class="flex justify-center w-9 h-9 rounded-md">
                <img class="rounded-md w-9 h-9" :src="item.photo" v-if="item.photo" />
                <span v-else class="w-9 h-9 text-blueCrayola border text-center font-semibold rounded-md p-1 flex justify-center items-center">
                  {{ $getInitials(`${item.fname} ${item.lname}`) }}
                </span>
              </div>
              <div class="flex flex-col flex-grow">
                <p>{{ `${item.fname} ${item.lname}` }}</p>
                <div class="text-xs flex font-semibold w- text-romanSilver uppercase">
                  <p class="opacity-50 mr-2">{{ !item.orgFunction ? "-" : item.orgFunction.name }}</p>
                </div>
              </div>
              <input type="radio" name="" :value="item" v-model="selectedItem" />
            </div>
          </div>
        </div>
      </template>
    </RightSideBar>
  </div>
</template>

<script>
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input"
import RightSideBar from "@/components/RightSideBar"

export default {
  components: {
    SearchInput,
    RightSideBar,
  },
  data(){
    return {
      isOpen: false,
      employeeInfo: {},
      employees: [],
      timeout: null,
      isLoading: true,
      isFetchingEmployeeInfo: false,
      selectedItem: {},
      searchInput: null
    }
  },
  methods: {
    navigateToNewPromotion(employeeInfo) {
      this.$router.push({
        name: 'NewPromotion',
        params: {
          employeeInfo
        }
      });
    },
    toggle(){
      this.selectedItem = {}
      this.searchInput = null
      this.isOpen = !this.isOpen
    },
    onSelectedEmployees(employee){
      this.$_getEmployeeTotalGross(employee.userId).then(({ data }) => {
        console.log(data)
        const employeeInfo = {...employee, payInfo: data.totalGrossAmount}
        this.navigateToNewPromotion(employeeInfo);
      }).catch(() => {
        const employeeInfo = {...employee, payInfo: {}}
        this.navigateToNewPromotion(employeeInfo);
      })
    },
    async onSearchEmployees(queryString, items) {
      this.isLoading = true
      clearTimeout(this.timeout)
      if (!queryString) {
        const qs = `&perPage=${items}`
        await this.$_getAllEmployeesWithQueries(qs).then(({ data }) => {
          this.employees = data.data.employees
          this.isLoading = false
        })
      }
      this.timeout = setTimeout(async () => {
        const qs = `&search=${queryString}&perPage=${items}`
        await this.$_getAllEmployeesWithQueries(qs).then(({ data }) => {
          this.employees = data.data.employees
          this.isLoading = false
        })
      }, 500)
    },
  },
  mounted() {
    this.onSearchEmployees('', 10)
  }
}
</script>

<style>
.rightSidebar {
  width: 520px !important;
}
</style>
<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}
input[type="radio"]:checked {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}
</style>
